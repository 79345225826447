/** @type {HTMLTemplateElement} */
const articleTemplate = document.querySelector(`template.article`);

class ArticleElement extends HTMLElement {
	constructor() {
		super();
		this.appendChild(articleTemplate.content.cloneNode(true));
	}

	/**
	 * @param titleName {string}
	 */
	set titleName(titleName) {
		const titleElement = this.querySelector(`h1`);
		titleElement.innerHTML = titleName;
	}

	/**
	 * @param src {string}
	 * @param width {number}
	 * @param height {number}
	 */
	media(src, width, height) {
		const slotMediaElement = this.querySelector(`slot[name="media"]`);
		/** @type {HTMLIFrameElement} */
		const iframeElement = document.createElement(`iframe`);
		iframeElement.src = src;
		iframeElement.width = `${width}`;
		iframeElement.height = `${height}`;
		iframeElement.allowFullscreen = true;
		this.removeMedia();
		slotMediaElement.appendChild(iframeElement);
	}

	removeMedia() {
		const slotMediaElement = this.querySelector(`slot[name="media"]`);
		slotMediaElement.innerHTML = "";
	}

	/**
	 * @param content {string}
	 * @param type {string}
	 */
	content(content, type) {
		const slotContentElement = this.querySelector(`slot[name="content"]`);
		slotContentElement.innerHTML = content;
		switch (type) {
			case `html`:
				slotContentElement.style.whiteSpace = `normal`;
				break;
			case `plain`:
				slotContentElement.style.whiteSpace = `break-spaces`;
				break;
			default:
				console.error(`Unknown type`, type);
		}
	}
}

customElements.define(`recess-article`, ArticleElement, {extends: `article`});
